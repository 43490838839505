<template>
  <div>
    <b-overlay :show="show" rounded="sm">
      <b-card no-body class="mb-0 pb-2">
        <div class="m-2">
          <div class="d-flex justify-content-between">
            <div class="flex-grow-1">
              <div class="row">
                <div class="col-md-3">
                  <v-select 
                      class="w-100" 
                      v-model="record_filters.country_code" 
                      placeholder="Select Country" 
                      :clearable="true" 
                      :reduce="(option) => option.code" 
                      :options="get_countries"
                      label="name"
                      @input="filterDropdown(record_filters.country_code)"
                    >
                    <template v-slot:option="option">
                        {{ option.name }}
                    </template>
                  </v-select>
                </div>
                <div class="col-md-3">
                  <v-select 
                      class="w-100" 
                      v-model="record_filters.billing_cycle" 
                      placeholder="Select Billing Cycle" 
                      :clearable="true" 
                      :reduce="(option) => option.name" 
                      :options="get_billing_cycles" 
                      label="label"
                      @input="filterDropdown(record_filters.billing_cycle)"
                    >
                    <template v-slot:option="option">
                        {{ option.label }}
                    </template>
                  </v-select>
                </div>
              </div>
            </div>
            <PlansAdd
              :manage="managePlans"
              :get_billing_cycles="get_billing_cycles"
              :get_billing_plan_types="get_billing_plan_types"
              :get_currencies="get_currencies"
              :get_countries="get_countries"
              @emitPlanAdded="getPlans"
            ></PlansAdd>
          </div>
        </div>
        <b-table-simple :key="refreshScreen" v-if="get_billing_plans.total > 0" hover caption-top responsive
          class="rounded-bottom mb-0">
          <b-thead head-variant="light">
            <b-tr>
              <b-th>No.</b-th>
              <b-th>Country Code</b-th>
              <b-th>Plan Name</b-th>
              <b-th>Billing Cycle</b-th>
              <b-th>Price</b-th>
              <b-th>Action</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <template v-for="(item, index) in get_billing_plans.data">
              <b-tr :key="index">
                <b-td>{{ index + 1 }}</b-td>
                <b-td>{{ item.country_code }}</b-td>
                <b-td>{{ item.name }}</b-td>
                <b-td>
                  <span class="text-capitalize">{{ item.billing_cycle }}</span>
                </b-td>
                <b-td>
                  {{ item.price }}
                  {{ item.currency_code }}
                </b-td>
                <b-td>
                  <div class="d-flex align-items-center">
                    <b-dropdown variant="link" no-caret>
                      <template #button-content>
                        <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                      </template>
                      <b-dropdown-item @click="editBtn(item)">
                        <div>
                          <feather-icon icon="EditIcon" />
                          <span class="align-middle ml-50">Edit</span>
                        </div>
                      </b-dropdown-item>
                      <b-dropdown-item @click="deleteBtn(item._id)">
                        <div>
                          <b-spinner v-if="loading" small class="mr-1" />
                          <feather-icon v-else icon="TrashIcon" />
                          <span class="align-middle ml-50">Delete</span>
                        </div>
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                </b-td>
              </b-tr>
            </template>
          </b-tbody>
        </b-table-simple>
        <div class="mx-2 mt-1">
          <div class="d-flex justify-content-between align-items-center flex-column flex-md-row">
            <div class="dataTables_info mt-1">Showing {{ get_billing_plans.from }} to {{ get_billing_plans.to }} of {{ get_billing_plans.total }}
              entries</div>
            <pagination class="mt-1 mb-0" :data="get_billing_plans" :limit=2 @pagination-change-page="getPlans"></pagination>
          </div>
        </div>
      </b-card>
    </b-overlay>
    <PlansEdit
      :manage="managePlans"
      :get_billing_cycles="get_billing_cycles"
      :get_billing_plan_types="get_billing_plan_types"
      :get_currencies="get_currencies"
      :get_countries="get_countries"
      @emitPlanUpdated="getPlans"
      :edit_plan="edit_plan"
    ></PlansEdit>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BOverlay,
  BLink,
  BBadge,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTableSimple,
  BThead,
  BFormCheckbox,
  BTr,
  BTh,
  BTd,
  BTbody,
  BTfoot,
  BCardHeader,
  BCardBody,
  BSpinner,
} from "bootstrap-vue";
import vSelect from "vue-select";
import PlansAdd from "./PlansAdd.vue";
import PlansEdit from "./PlansEdit.vue";
import AddEditCategory from "../app/list/users-list/AddEditCategory.vue";
import axios from "axios";
import * as _ from "lodash";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
export default {
  components: {
    PlansAdd,
    PlansEdit,
    BOverlay,
    AddEditCategory,
    BCard,
    BFormSelect,
    BRow,
    BCol,
    BCardHeader,
    BCardBody,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTableSimple,
    BFormCheckbox,
    BThead,
    BTr,
    BTh,
    ToastificationContent,
    BTd,
    BTbody,
    BTfoot,
    BFormInput,
    vSelect,
    PlansAdd,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      classId: "",
      show: false,
      refreshScreen: 0,
      alertData: "",
      managePlans: false,
      loading: false,

      get_billing_cycles: [],
      get_billing_plan_types: [],
      get_currencies: [],
      get_countries: [],
      record_filters: {},
      get_billing_plans: {},

      edit_plan: {},
    };
  },
  mounted() {
    this.managePlans = JSON.parse(localStorage.getItem("permission")).permission.some(permission => permission === "manage_plans");
  },
  created() {
    this.getPlans();
  },
  methods: {
    setPermission() {
      if (localStorage.getItem("_t")) {
        axios
          .get(process.env.VUE_APP_API_URL + "/tokencheck", {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("_t"),
            },
          })
          .then((get_response) => {
            if (get_response.data.message == "authenticated") {
              var permissiondata = JSON.stringify(get_response.data.permission);
              if (permissiondata) {
                localStorage.setItem("permission", permissiondata);
                this.gettabledata();
              }
            }
          })
          .catch(function (error) {
            if (error.response) {
              if (error.response.data.message == "Unauthenticated.") {
                localStorage.removeItem("permission", { permission: [] });
              }
            } else if (error.request) {
              console.log(error.request);
            } else {
              console.log("Error", error.message);
            }
          });
      }
    },
    showToast(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Notification",
          icon: "BellIcon",
          text: this.alertData,
          variant,
        },
      });
    },
    getPlans: function (page = 1) {
      var url = process.env.VUE_APP_API_URL + `/billing/plans?page=${page}`;
      const payload = this.record_filters
      this.show = true;
      axios
        .post(url, payload, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("_t"),
          },
        })
        .then((res) => {
          this.get_billing_plans = res.data.billing_plans;
          this.get_billing_cycles = res.data.billing_cycles;
          this.get_billing_plan_types = res.data.billing_plan_types;
          this.get_currencies = res.data.currencies;
          this.get_countries = res.data.countries;
          this.show = false;
        })
        .catch(function (error) {
          console.log("error", error);
        });
    },

    editBtn(planData) {
      this.edit_plan = planData;
      this.$bvModal.show("edit-modal");
    },
    deleteBtn(id) {
      console.log('deleteBtn id=',id)
      Swal.fire({
        title: "Are you sure?",
        text: `Do You want to delete this plan!`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.delete(id)
        }
      });
    },
    async delete(id) {
      this.loading = true;
      try {
        const resp = await axios.delete(`${process.env.VUE_APP_API_URL}/billing/plans/${id}`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("_t"),
          },
        });

        console.log('resp.data=',resp.data.message)
        this.alertData = resp.data.message;
        this.showToast("success");
        this.loading = false;
        this.getPlans();
      } catch (error) {
        this.errorMsg = Helpers.catchFunction(error);
        this.alertData = resp.data.message;
        this.showToast("danger");
        this.loading = false;
      }
    },
    filterDropdown() {
      this.getPlans();
    },
  },
};
</script>
<style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
</style>
<style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";

  .add-catrgory-button {
    margin-top: 10px;
  }
</style>
<style>
  .vs__open-indicator {
    fill: #9d9d9d !important;
  }
</style>
 